<template>
  <div class="login" @keydown.enter="login">
    <div style="display: flex;flex-direction: row ;justify-content: space-between">
      <div class="loginLogo"></div>
      <!--      <img class="loginLogo" src="~@/assets/img/logo_index2.png"/>-->
      <div style="display: flex;vertical-align: center">
        <el-button type="primary" style="margin-right: 20px" @click="downloadVisible=true">下载电梯智慧物联</el-button>
        <el-button type="primary" style="margin-right: 20px" @click="downloadVisibleApp=true">下载电梯智慧物联管理平台</el-button>
      </div>
    </div>

    <div class="content">
      <div class="login-con">
        <div class="login-wrapper">
          <div class="login-header">
            {{$l("login.welcome", "梯智广达电梯智慧物联网平台")}}
          </div>
          <el-form ref="loginForm" :model="user" :rules="rules">
            <el-form-item prop="username">
              <el-input
                v-model="user.username"
                :placeholder="$l('login.username','请输入账号')"
                size="medium"
                clearable
                prefix-icon="iconfont user"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="user.password"
                :placeholder="$l('login.password','请输入密码')"
                size="medium"
                clearable
                prefix-icon="iconfont password"
                show-password></el-input>
            </el-form-item>
            <el-button
              class="login-btn"
              :loading="loading"
              size="medium"
              style="width: 100%;margin-top: 10px"
              type="warning"
              @click="login">
              {{$l("login.login", "登 录")}}
            </el-button>
          </el-form>
          <div v-if="false" style="padding: 15px 0 0 15px;overflow:hidden;">
            <el-button size="small" style="float: right;" @click="dialogVisible=true">忘记密码</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=2022007451" target="_blank">
        CopyRight © {{new Date().getFullYear()}} 山东梯智物联科技有限公司 <img :src="img"/>鲁ICP备2022007451号-1号</a>
      <br/>此网站属于山东梯智物联科技有限公司所有
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      title="找回密码"
      width="460px"
      @close="dialogClose">
      <el-form ref="findPasswordForm" :model="findPassword" :rules="findPasswordRules" size="small">
        <el-form-item prop="phone">
          <el-input v-model="findPassword.phone" :placeholder="$l('common.enter','请输入')">
            <template slot="prepend">手机号</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="findPassword.code" :placeholder="$l('common.enter','请输入')" style="width: 280px">
            <template slot="prepend">验证码</template>
          </el-input>
          <el-button
            :disabled="sendMsgBtnDisabled"
            :loading="sendMsgBtnLoading"
            size="mini"
            style="margin-left: 5px"
            type="success"
            @click="sendMsg">
            {{sendMsgBtnText}}
          </el-button>
        </el-form-item>
        <el-form-item prop="newPassword">
          <el-input v-model="findPassword.newPassword" :placeholder="$l('common.enter','请输入')" type="password">
            <template slot="prepend">新密码</template>
          </el-input>
        </el-form-item>
        <el-button
          :loading="loading"
          size="small"
          style="width: 100%"
          type="primary"
          @click="nextStep">
          提交
        </el-button>
      </el-form>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="downloadVisible"
      title="下载电梯智慧物联"
      width="460px"
      @close="dialogDownloadClose">
      <a style="font-size: 22px;font-weight: bold" href="https://gr-iot.cn/物业端.apk">点击本段文字下载app或扫码下载</a>
      <vueQr text="https://gr-iot.cn/物业端.apk" :logo-scale="0.4"></vueQr>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="downloadVisibleApp"
      title="下载电梯智慧物联管理平台"
      width="460px"
      @close="dialogDownloadClose">
      <a style="font-size: 22px;font-weight: bold" href="https://gr-iot.cn/维保端.apk">点击本段文字下载app或扫码下载</a>
      <vueQr text="https://gr-iot.cn/维保端.apk" :logo-scale="0.4"></vueQr>
    </el-dialog>
  </div>
</template>

<script>
  import loginUtil from "@/util/loginUtil";
  import {Notification} from "element-ui";
  import {myI18n} from "@/i18n";
  import vueQr from "vue-qr";
  export default {
    components:{vueQr},
    data() {
      const isDev = process.env.NODE_ENV === "development";
      return {
        dialogVisible: false,
        countdownSeconds: 60,
        sendMsgBtnDisabled: false,
        sendMsgBtnText: "发送短信",
        sendMsgBtnLoading: false,
        img:require("../../assets/img.png"),
        // imageUrl: require("../../assets/img/logo_pages_small2.png"),//icon路径
        user: {
          username: isDev ? "admin" : "",
          password: isDev ? "a123456" : "",
          platform: "pc",
        },
        findPassword: {
          phone: "",
          code: "",
          newPassword: "",
        },
        rules: {
          phone: {required: true, message: "手机号不能为空", trigger: "blur"},
          password: {required: true, message: "密码不能为空", trigger: "blur"},
        },
        findPasswordRules: {
          phone: {required: true, message: "不能为空", trigger: "blur"},
          code: {required: true, message: "不能为空", trigger: "blur"},
          newPassword: {
            required: true,
            min: 6,
            max: 25,
            message: "不能为空",
            trigger: "blur",
          },
        },
        loading: false,
        downloadVisible: false,
        downloadVisibleApp: false,
      };
    },
    created() {
      console.log(this.getOsInfo(),166);
    },
    methods: {

      getOsInfo() {
        var userAgent = navigator.userAgent.toLowerCase();
        let name = "Unknown";
        let version = "Unknown";
        if (userAgent.indexOf("win") > -1) {
          name = "Windows";
          if (userAgent.indexOf("windows nt 5.0") > -1) {
            version = "Windows 2000";
          } else if (
            userAgent.indexOf("windows nt 5.1") > -1 ||
            userAgent.indexOf("windows nt 5.2") > -1
          ) {
            version = "Windows XP";
          } else if (userAgent.indexOf("windows nt 6.0") > -1) {
            version = "Windows Vista";
          } else if (
            userAgent.indexOf("windows nt 6.1") > -1 ||
            userAgent.indexOf("windows 7") > -1
          ) {
            version = "Windows 7";
          } else if (
            userAgent.indexOf("windows nt 6.2") > -1 ||
            userAgent.indexOf("windows 8") > -1
          ) {
            version = "Windows 8";
          } else if (userAgent.indexOf("windows nt 6.3") > -1) {
            version = "Windows 8.1";
          } else if (
            userAgent.indexOf("windows nt 6.2") > -1 ||
            userAgent.indexOf("windows nt 10.0") > -1
          ) {
            version = "Windows 10";
          } else {
            version = "Unknown";
          }
        } else if (userAgent.indexOf("iphone") > -1) {
          name = "Iphone";
        } else if (userAgent.indexOf("mac") > -1) {
          name = "Mac";
        } else if (
          userAgent.indexOf("x11") > -1 ||
          userAgent.indexOf("unix") > -1 ||
          userAgent.indexOf("sunname") > -1 ||
          userAgent.indexOf("bsd") > -1
        ) {
          name = "Unix";
        } else if (userAgent.indexOf("linux") > -1) {
          if (userAgent.indexOf("android") > -1) {
            name = "Android";
          } else {
            name = "Linux";
          }
        } else {
          name = "Unknown";
        }
        return {name, version};
      },

      login() {
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            this.loading = true;
            this.$http
              .post("login/iot-back-end", {login:this.user.username,password:this.user.password})
              .then(data => {
                this.loading = false;

                if (data.success) {
                  loginUtil.login(data);
                  // const os = localStorage.getItem("os");
                  // if (os==="old") {
                  this.$router.push({path: "/"});
                  // }else {
                  //   this.$router.push({path: "/index/index"});
                  // }

                }else {
                  Notification.error({title: myI18n("common.tip", "提示"), message: data.message});
                }

              })
              .catch(() => {
                this.loading = false;
              });
          }
        });
      },
      countdown() {
        if (this.countdownSeconds === 0) {
          this.sendMsgBtnDisabled = false;
          this.sendMsgBtnText = "发送短信";
          this.countdownSeconds = 60;
        } else {
          this.sendMsgBtnDisabled = true;
          this.sendMsgBtnText = "重新发送" + this.countdownSeconds + "s";
          this.countdownSeconds--;
          setTimeout(() => {
            this.countdown();
          }, 1000);
        }
      },
      sendMsg() {
        this.findPasswordRules.code.required = false;
        this.findPasswordRules.newPassword.required = false;
        this.$refs.findPasswordForm.validate(valid => {
          if (valid) {
            this.sendMsgBtnLoading = true;
            let params = {
              phone: this.findPassword.phone,
            };
            this.$http
              .get("account/sendMsg", params)
              .then(() => {
                this.countdown();
                this.$message.success("短信发送成功");
                this.sendMsgBtnLoading = false;
              })
              .catch(() => {
                this.sendMsgBtnLoading = false;
              });
          }
        });
      },
      nextStep() {
        this.findPasswordRules.code.required = true;
        this.findPasswordRules.newPassword.required = true;
        this.$refs.findPasswordForm.validate(valid => {
          if (valid) {
            let params = {
              phone: this.findPassword.phone,
              code: this.findPassword.code,
              newPassword: this.findPassword.newPassword,
            };
            this.$http
              .get("account/forgotPassword", params)
              .then(() => {
                this.$message.success("密码修改成功");
                this.dialogVisible = false;
              })
              .catch(error => {
                if (error.response) {
                  this.$message.error("密码修改失败," + error.response.data.message);
                }
              });
          }
        });
      },
      dialogClose() {
        this.$refs.findPasswordForm.resetFields();
        this.sendMsgBtnLoading = false;
      },
      dialogDownloadClose() {
        // this.$refs.findPasswordForm.resetFields();
        // this.sendMsgBtnLoading = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;

  .loginLogo {
    height: 46px;
    margin: 10px 0 10px 2%;
  }
.appLogo {
  width: 150px;
    //margin: 10px 0 10px 2%;
  }

  .content {
    position: relative;
    background: url(~@/assets/img/login_bg.png) center;
    background-size: cover;
    height: 70%;
  }

  .login-con {
    width: 360px;
    height: 100%;
    position: absolute;
    right: 160px;
    text-align: center;
    background-color: rgba(0, 100, 173, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-wrapper {
    width: 100%;
    padding: 0 30px;
    margin-top: -20%;
  }

  .login-header {
    font-size: 22px;
    color: white;
    font-weight: bold;
    margin-bottom: 35px;
  }

  .copyright {
    margin-top: 30px;
    text-align: center;
    left: 10%;
    right: 10%;
    color: #666666;
    font-size: 12px;
    line-height: 20px;

    a {
      color: #666666;
    }
  }
}
</style>
<style lang="scss">
.login-wrapper {
  .login-btn {
    background-color: #FFF100;
    border: #FFF100;
    color: #0064AD;
    font-size: 14px;

    &:hover {
      background-color: #E6A23C;
    }
  }

  .iconfont {
    color: #0064AD;
    margin-left: 10px;
  }

  .el-input input {
    padding-left: 40px;
    border: none;
  }
}
</style>
